/* eslint-disable */
/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT BY HAND!
 * Run `yarn upgrade:icons` to regenerate.
 * You may edit src/style/icon-library.mjs to adjust behavior. */
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faTwitter as fabTwitter,
  faGoogle as fabGoogle,
  faTwitch as fabTwitch,
  faYoutube as fabYoutube,
  faWpforms as fabWpforms,
  faBattleNet as fabBattleNet,
  faFacebook as fabFacebook,
  faInstagram as fabInstagram,
  faLinkedinIn as fabLinkedinIn,
  faPlaystation as fabPlaystation,
  faSteam as fabSteam,
  faXTwitter as fabXTwitter,
  faWizardsOfTheCoast as fabWizardsOfTheCoast,
  faXbox as fabXbox,
  faUnity as fabUnity,
  faTiktok as fabTiktok
} from '@fortawesome/free-brands-svg-icons'
import { faListTimeline as farListTimeline } from '@fortawesome/pro-regular-svg-icons'
import {
  faTrash as fasTrash,
  faSquare as fasSquare,
  faCheck as fasCheck,
  faMinus as fasMinus,
  faGripDotsVertical as fasGripDotsVertical,
  faTriangle as fasTriangle,
  faBadge as fasBadge,
  faCaretUp as fasCaretUp,
  faCaretDown as fasCaretDown
} from '@fortawesome/pro-solid-svg-icons'
import {
  faChevronDown as falChevronDown,
  faChevronUp as falChevronUp,
  faClone as falClone,
  faSearch as falSearch,
  faInfoCircle as falInfoCircle,
  faTrash as falTrash,
  faTrophy as falTrophy,
  faArrowCircleRight as falArrowCircleRight,
  faMagnifyingGlassMinus as falMagnifyingGlassMinus,
  faMagnifyingGlassPlus as falMagnifyingGlassPlus,
  faFileContract as falFileContract,
  faCheck as falCheck,
  faTimes as falTimes,
  faAsterisk as falAsterisk,
  faPencilAlt as falPencilAlt,
  faSpinner as falSpinner,
  faTilde as falTilde,
  faLockAlt as falLockAlt,
  faSquare as falSquare,
  faEllipsisH as falEllipsisH,
  faPlus as falPlus,
  faCheckSquare as falCheckSquare,
  faTriangleExclamation as falTriangleExclamation,
  faHourglassHalf as falHourglassHalf,
  faExclamationTriangle as falExclamationTriangle,
  faChevronRight as falChevronRight,
  faFileImport as falFileImport,
  faShieldCheck as falShieldCheck,
  faEnvelope as falEnvelope,
  faPhoneLaptop as falPhoneLaptop,
  faKey as falKey,
  faGhost as falGhost,
  faFlag as falFlag,
  faPennant as falPennant,
  faCalendarEdit as falCalendarEdit,
  faCut as falCut,
  faCopy as falCopy,
  faBookCopy as falBookCopy,
  faPaste as falPaste,
  faTrashAlt as falTrashAlt,
  faBell as falBell,
  faCalendarPlus as falCalendarPlus,
  faVideo as falVideo,
  faLineHeight as falLineHeight,
  faCalendarLinesPen as falCalendarLinesPen,
  faLock as falLock,
  faCircleCheck as falCircleCheck,
  faSwords as falSwords,
  faHandsHoldingHeart as falHandsHoldingHeart,
  faCalendarDay as falCalendarDay,
  faChevronLeft as falChevronLeft,
  faAngleLeft as falAngleLeft,
  faAngleRight as falAngleRight,
  faCalendar as falCalendar,
  faMoon as falMoon,
  faCircle as falCircle,
  faTimesCircle as falTimesCircle,
  faCheckCircle as falCheckCircle,
  faRefresh as falRefresh,
  faHome as falHome,
  faSchool as falSchool,
  faUsers as falUsers,
  faUsersCrown as falUsersCrown,
  faNewspaper as falNewspaper,
  faShoppingCart as falShoppingCart,
  faMessagesQuestion as falMessagesQuestion,
  faBars as falBars,
  faChevronDoubleLeft as falChevronDoubleLeft,
  faChevronDoubleRight as falChevronDoubleRight,
  faAngleDown as falAngleDown,
  faLightbulb as falLightbulb,
  faUserSecret as falUserSecret,
  faBalanceScale as falBalanceScale,
  faChevronCircleDown as falChevronCircleDown,
  faShareSquare as falShareSquare,
  faLink as falLink,
  faArchive as falArchive,
  faAward as falAward,
  faQuestion as falQuestion,
  faAt as falAt,
  faUserShield as falUserShield,
  faUpRightFromSquare as falUpRightFromSquare,
  faMessageExclamation as falMessageExclamation,
  faStar as falStar,
  faGlobe as falGlobe,
  faPassport as falPassport,
  faTag as falTag,
  faGamepad as falGamepad,
  faGameConsoleHandheld as falGameConsoleHandheld,
  faHandScissors as falHandScissors,
  faTable as falTable,
  faSwatchbook as falSwatchbook,
  faExternalLink as falExternalLink,
  faSiren as falSiren,
  faFire as falFire,
  faFileSearch as falFileSearch,
  faUserCog as falUserCog,
  faBadgeCheck as falBadgeCheck,
  faAddressCard as falAddressCard,
  faCodeMerge as falCodeMerge,
  faChess as falChess,
  faList as falList,
  faShield as falShield,
  faEye as falEye,
  faEdit as falEdit,
  faThumbtack as falThumbtack,
  faPencil as falPencil,
  faDollarSign as falDollarSign,
  faTicket as falTicket,
  faMegaphone as falMegaphone,
  faFileExport as falFileExport,
  faMessages as falMessages,
  faArrowLeft as falArrowLeft,
  faRobot as falRobot,
  faMapMarkerCheck as falMapMarkerCheck,
  faMapMarker as falMapMarker,
  faArrowAltCircleDown as falArrowAltCircleDown,
  faChevronCircleLeft as falChevronCircleLeft,
  faChevronCircleRight as falChevronCircleRight,
  faCaretRight as falCaretRight,
  faImages as falImages,
  faClipboardCheck as falClipboardCheck,
  faMailbox as falMailbox,
  faSignOutAlt as falSignOutAlt,
  faUser as falUser,
  faClipboard as falClipboard,
  faCog as falCog,
  faUnlock as falUnlock,
  faCameraMovie as falCameraMovie,
  faRunning as falRunning,
  faUserHeadset as falUserHeadset,
  faUserBountyHunter as falUserBountyHunter,
  faPlay as falPlay,
  faArrowsLeftRight as falArrowsLeftRight,
  faRecycle as falRecycle,
  faMagic as falMagic,
  faWrench as falWrench,
  faGripLines as falGripLines,
  faChevronCircleUp as falChevronCircleUp,
  faUserMinus as falUserMinus,
  faEyeSlash as falEyeSlash,
  faRotateLeft as falRotateLeft,
  faSearchPlus as falSearchPlus,
  faSearchMinus as falSearchMinus,
  faClock as falClock,
  faRedo as falRedo,
  faArrowUpRightAndArrowDownLeftFromCenter as falArrowUpRightAndArrowDownLeftFromCenter,
  faUserFriends as falUserFriends,
  faGamepadAlt as falGamepadAlt,
  faChild as falChild,
  faCommentTimes as falCommentTimes,
  faHandshake as falHandshake,
  faFlagPennant as falFlagPennant,
  faListUl as falListUl,
  faGrid2 as falGrid2,
  faCommentLines as falCommentLines,
  faHourglassStart as falHourglassStart,
  faHourglassEnd as falHourglassEnd,
  faArrowDownToLine as falArrowDownToLine,
  faSignOut as falSignOut,
  faFamily as falFamily,
  faWebAwesome as falWebAwesome,
  faFilterSlash as falFilterSlash,
  faAddressBook as falAddressBook,
  faQuestionCircle as falQuestionCircle,
  faSeedling as falSeedling,
  faCodeFork as falCodeFork,
  faBarsFilter as falBarsFilter,
  faFilter as falFilter,
  faSignIn as falSignIn,
  faArrowUpToLine as falArrowUpToLine,
  faArrowsRotate as falArrowsRotate,
  faGavel as falGavel,
  faBoxCheck as falBoxCheck,
  faFileUpload as falFileUpload,
  faFileWord as falFileWord,
  faCaretCircleDown as falCaretCircleDown,
  faAlignLeft as falAlignLeft,
  faGripVertical as falGripVertical,
  faBringForward as falBringForward,
  faSave as falSave,
  faLockHashtag as falLockHashtag,
  faHeart as falHeart,
  faPenToSquare as falPenToSquare,
  faSquareCheck as falSquareCheck,
  faPaperPlane as falPaperPlane,
  faFileUser as falFileUser,
  faTools as falTools,
  faReply as falReply,
  faPowerOff as falPowerOff,
  faGraduationCap as falGraduationCap,
  faIceCream as falIceCream,
  faChessPawn as falChessPawn,
  faSync as falSync,
  faInboxIn as falInboxIn,
  faBan as falBan,
  faDownload as falDownload
} from '@fortawesome/pro-light-svg-icons'
import {
  faCatSpace as fadCatSpace,
  faPennant as fadPennant,
  faNarwhal as fadNarwhal,
  faPaperPlane as fadPaperPlane,
  faSmileBeam as fadSmileBeam,
  faSmile as fadSmile,
  faGrinSquintTears as fadGrinSquintTears,
  faKissBeam as fadKissBeam,
  faGrinBeamSweat as fadGrinBeamSweat,
  faGrinHearts as fadGrinHearts,
  faImages as fadImages,
  faRocketLaunch as fadRocketLaunch,
  faHandsHeart as fadHandsHeart
} from '@fortawesome/pro-duotone-svg-icons'
import customIcons from './icon-library-custom'
const Init = () => {
  library.add(
    ...customIcons,
    fabTwitter,
    fabGoogle,
    fabTwitch,
    fabYoutube,
    fabWpforms,
    fabBattleNet,
    fabFacebook,
    fabInstagram,
    fabLinkedinIn,
    fabPlaystation,
    fabSteam,
    fabXTwitter,
    fabWizardsOfTheCoast,
    fabXbox,
    fabUnity,
    fabTiktok,
    farListTimeline,
    fasTrash,
    fasSquare,
    fasCheck,
    fasMinus,
    fasGripDotsVertical,
    fasTriangle,
    fasBadge,
    fasCaretUp,
    fasCaretDown,
    falChevronDown,
    falChevronUp,
    falClone,
    falSearch,
    falInfoCircle,
    falTrash,
    falTrophy,
    falArrowCircleRight,
    falMagnifyingGlassMinus,
    falMagnifyingGlassPlus,
    falFileContract,
    falCheck,
    falTimes,
    falAsterisk,
    falPencilAlt,
    falSpinner,
    falTilde,
    falLockAlt,
    falSquare,
    falEllipsisH,
    falPlus,
    falCheckSquare,
    falTriangleExclamation,
    falHourglassHalf,
    falExclamationTriangle,
    falChevronRight,
    falFileImport,
    falShieldCheck,
    falEnvelope,
    falPhoneLaptop,
    falKey,
    falGhost,
    falFlag,
    falPennant,
    falCalendarEdit,
    falCut,
    falCopy,
    falBookCopy,
    falPaste,
    falTrashAlt,
    falBell,
    falCalendarPlus,
    falVideo,
    falLineHeight,
    falCalendarLinesPen,
    falLock,
    falCircleCheck,
    falSwords,
    falHandsHoldingHeart,
    falCalendarDay,
    falChevronLeft,
    falAngleLeft,
    falAngleRight,
    falCalendar,
    falMoon,
    falCircle,
    falTimesCircle,
    falCheckCircle,
    falRefresh,
    falHome,
    falSchool,
    falUsers,
    falUsersCrown,
    falNewspaper,
    falShoppingCart,
    falMessagesQuestion,
    falBars,
    falChevronDoubleLeft,
    falChevronDoubleRight,
    falAngleDown,
    falLightbulb,
    falUserSecret,
    falBalanceScale,
    falChevronCircleDown,
    falShareSquare,
    falLink,
    falArchive,
    falAward,
    falQuestion,
    falAt,
    falUserShield,
    falUpRightFromSquare,
    falMessageExclamation,
    falStar,
    falGlobe,
    falPassport,
    falTag,
    falGamepad,
    falGameConsoleHandheld,
    falHandScissors,
    falTable,
    falSwatchbook,
    falExternalLink,
    falSiren,
    falFire,
    falFileSearch,
    falUserCog,
    falBadgeCheck,
    falAddressCard,
    falCodeMerge,
    falChess,
    falList,
    falShield,
    falEye,
    falEdit,
    falThumbtack,
    falPencil,
    falDollarSign,
    falTicket,
    falMegaphone,
    falFileExport,
    falMessages,
    falArrowLeft,
    falRobot,
    falMapMarkerCheck,
    falMapMarker,
    falArrowAltCircleDown,
    falChevronCircleLeft,
    falChevronCircleRight,
    falCaretRight,
    falImages,
    falClipboardCheck,
    falMailbox,
    falSignOutAlt,
    falUser,
    falClipboard,
    falCog,
    falUnlock,
    falCameraMovie,
    falRunning,
    falUserHeadset,
    falUserBountyHunter,
    falPlay,
    falArrowsLeftRight,
    falRecycle,
    falMagic,
    falWrench,
    falGripLines,
    falChevronCircleUp,
    falUserMinus,
    falEyeSlash,
    falRotateLeft,
    falSearchPlus,
    falSearchMinus,
    falClock,
    falRedo,
    falArrowUpRightAndArrowDownLeftFromCenter,
    falUserFriends,
    falGamepadAlt,
    falChild,
    falCommentTimes,
    falHandshake,
    falFlagPennant,
    falListUl,
    falGrid2,
    falCommentLines,
    falHourglassStart,
    falHourglassEnd,
    falArrowDownToLine,
    falSignOut,
    falFamily,
    falWebAwesome,
    falFilterSlash,
    falAddressBook,
    falQuestionCircle,
    falSeedling,
    falCodeFork,
    falBarsFilter,
    falFilter,
    falSignIn,
    falArrowUpToLine,
    falArrowsRotate,
    falGavel,
    falBoxCheck,
    falFileUpload,
    falFileWord,
    falCaretCircleDown,
    falAlignLeft,
    falGripVertical,
    falBringForward,
    falSave,
    falLockHashtag,
    falHeart,
    falPenToSquare,
    falSquareCheck,
    falPaperPlane,
    falFileUser,
    falTools,
    falReply,
    falPowerOff,
    falGraduationCap,
    falIceCream,
    falChessPawn,
    falSync,
    falInboxIn,
    falBan,
    falDownload,
    fadCatSpace,
    fadPennant,
    fadNarwhal,
    fadPaperPlane,
    fadSmileBeam,
    fadSmile,
    fadGrinSquintTears,
    fadKissBeam,
    fadGrinBeamSweat,
    fadGrinHearts,
    fadImages,
    fadRocketLaunch,
    fadHandsHeart
  )
}
export const IconProps = [
  ['fab', 'twitter'],
  ['fab', 'google'],
  ['fab', 'twitch'],
  ['fab', 'youtube'],
  ['fab', 'wpforms'],
  ['fab', 'battle-net'],
  ['fab', 'facebook'],
  ['fab', 'instagram'],
  ['fab', 'linkedin-in'],
  ['fab', 'playstation'],
  ['fab', 'steam'],
  ['fab', 'x-twitter'],
  ['fab', 'wizards-of-the-coast'],
  ['fab', 'xbox'],
  ['fab', 'unity'],
  ['fab', 'tiktok'],
  ['far', 'list-timeline'],
  ['fas', 'trash'],
  ['fas', 'square'],
  ['fas', 'check'],
  ['fas', 'minus'],
  ['fas', 'grip-dots-vertical'],
  ['fas', 'triangle'],
  ['fas', 'badge'],
  ['fas', 'caret-up'],
  ['fas', 'caret-down'],
  ['fal', 'chevron-down'],
  ['fal', 'chevron-up'],
  ['fal', 'clone'],
  ['fal', 'search'],
  ['fal', 'info-circle'],
  ['fal', 'trash'],
  ['fal', 'trophy'],
  ['fal', 'arrow-circle-right'],
  ['fal', 'magnifying-glass-minus'],
  ['fal', 'magnifying-glass-plus'],
  ['fal', 'file-contract'],
  ['fal', 'check'],
  ['fal', 'times'],
  ['fal', 'asterisk'],
  ['fal', 'pencil-alt'],
  ['fal', 'spinner'],
  ['fal', 'tilde'],
  ['fal', 'lock-alt'],
  ['fal', 'square'],
  ['fal', 'ellipsis-h'],
  ['fal', 'plus'],
  ['fal', 'check-square'],
  ['fal', 'triangle-exclamation'],
  ['fal', 'hourglass-half'],
  ['fal', 'exclamation-triangle'],
  ['fal', 'chevron-right'],
  ['fal', 'file-import'],
  ['fal', 'shield-check'],
  ['fal', 'envelope'],
  ['fal', 'phone-laptop'],
  ['fal', 'key'],
  ['fal', 'ghost'],
  ['fal', 'flag'],
  ['fal', 'pennant'],
  ['fal', 'calendar-edit'],
  ['fal', 'cut'],
  ['fal', 'copy'],
  ['fal', 'book-copy'],
  ['fal', 'paste'],
  ['fal', 'trash-alt'],
  ['fal', 'bell'],
  ['fal', 'calendar-plus'],
  ['fal', 'video'],
  ['fal', 'line-height'],
  ['fal', 'calendar-lines-pen'],
  ['fal', 'lock'],
  ['fal', 'circle-check'],
  ['fal', 'swords'],
  ['fal', 'hands-holding-heart'],
  ['fal', 'calendar-day'],
  ['fal', 'chevron-left'],
  ['fal', 'angle-left'],
  ['fal', 'angle-right'],
  ['fal', 'calendar'],
  ['fal', 'moon'],
  ['fal', 'circle'],
  ['fal', 'times-circle'],
  ['fal', 'check-circle'],
  ['fal', 'refresh'],
  ['fal', 'home'],
  ['fal', 'school'],
  ['fal', 'users'],
  ['fal', 'users-crown'],
  ['fal', 'newspaper'],
  ['fal', 'shopping-cart'],
  ['fal', 'messages-question'],
  ['fal', 'bars'],
  ['fal', 'chevron-double-left'],
  ['fal', 'chevron-double-right'],
  ['fal', 'angle-down'],
  ['fal', 'lightbulb'],
  ['fal', 'user-secret'],
  ['fal', 'balance-scale'],
  ['fal', 'chevron-circle-down'],
  ['fal', 'share-square'],
  ['fal', 'link'],
  ['fal', 'archive'],
  ['fal', 'award'],
  ['fal', 'question'],
  ['fal', 'at'],
  ['fal', 'user-shield'],
  ['fal', 'up-right-from-square'],
  ['fal', 'message-exclamation'],
  ['fal', 'star'],
  ['fal', 'globe'],
  ['fal', 'passport'],
  ['fal', 'tag'],
  ['fal', 'gamepad'],
  ['fal', 'game-console-handheld'],
  ['fal', 'hand-scissors'],
  ['fal', 'table'],
  ['fal', 'swatchbook'],
  ['fal', 'external-link'],
  ['fal', 'siren'],
  ['fal', 'fire'],
  ['fal', 'file-search'],
  ['fal', 'user-cog'],
  ['fal', 'badge-check'],
  ['fal', 'address-card'],
  ['fal', 'code-merge'],
  ['fal', 'chess'],
  ['fal', 'list'],
  ['fal', 'shield'],
  ['fal', 'eye'],
  ['fal', 'edit'],
  ['fal', 'thumbtack'],
  ['fal', 'pencil'],
  ['fal', 'dollar-sign'],
  ['fal', 'ticket'],
  ['fal', 'megaphone'],
  ['fal', 'file-export'],
  ['fal', 'messages'],
  ['fal', 'arrow-left'],
  ['fal', 'robot'],
  ['fal', 'map-marker-check'],
  ['fal', 'map-marker'],
  ['fal', 'arrow-alt-circle-down'],
  ['fal', 'chevron-circle-left'],
  ['fal', 'chevron-circle-right'],
  ['fal', 'caret-right'],
  ['fal', 'images'],
  ['fal', 'clipboard-check'],
  ['fal', 'mailbox'],
  ['fal', 'sign-out-alt'],
  ['fal', 'user'],
  ['fal', 'clipboard'],
  ['fal', 'cog'],
  ['fal', 'unlock'],
  ['fal', 'camera-movie'],
  ['fal', 'running'],
  ['fal', 'user-headset'],
  ['fal', 'user-bounty-hunter'],
  ['fal', 'play'],
  ['fal', 'arrows-left-right'],
  ['fal', 'recycle'],
  ['fal', 'magic'],
  ['fal', 'wrench'],
  ['fal', 'grip-lines'],
  ['fal', 'chevron-circle-up'],
  ['fal', 'user-minus'],
  ['fal', 'eye-slash'],
  ['fal', 'rotate-left'],
  ['fal', 'search-plus'],
  ['fal', 'search-minus'],
  ['fal', 'clock'],
  ['fal', 'redo'],
  ['fal', 'arrow-up-right-and-arrow-down-left-from-center'],
  ['fal', 'user-friends'],
  ['fal', 'gamepad-alt'],
  ['fal', 'child'],
  ['fal', 'comment-times'],
  ['fal', 'handshake'],
  ['fal', 'flag-pennant'],
  ['fal', 'list-ul'],
  ['fal', 'grid-2'],
  ['fal', 'comment-lines'],
  ['fal', 'hourglass-start'],
  ['fal', 'hourglass-end'],
  ['fal', 'arrow-down-to-line'],
  ['fal', 'sign-out'],
  ['fal', 'family'],
  ['fal', 'web-awesome'],
  ['fal', 'filter-slash'],
  ['fal', 'address-book'],
  ['fal', 'question-circle'],
  ['fal', 'seedling'],
  ['fal', 'code-fork'],
  ['fal', 'bars-filter'],
  ['fal', 'filter'],
  ['fal', 'sign-in'],
  ['fal', 'arrow-up-to-line'],
  ['fal', 'arrows-rotate'],
  ['fal', 'gavel'],
  ['fal', 'box-check'],
  ['fal', 'file-upload'],
  ['fal', 'file-word'],
  ['fal', 'caret-circle-down'],
  ['fal', 'align-left'],
  ['fal', 'grip-vertical'],
  ['fal', 'bring-forward'],
  ['fal', 'save'],
  ['fal', 'lock-hashtag'],
  ['fal', 'heart'],
  ['fal', 'pen-to-square'],
  ['fal', 'square-check'],
  ['fal', 'paper-plane'],
  ['fal', 'file-user'],
  ['fal', 'tools'],
  ['fal', 'reply'],
  ['fal', 'power-off'],
  ['fal', 'graduation-cap'],
  ['fal', 'ice-cream'],
  ['fal', 'chess-pawn'],
  ['fal', 'sync'],
  ['fal', 'inbox-in'],
  ['fal', 'ban'],
  ['fal', 'download'],
  ['fad', 'cat-space'],
  ['fad', 'pennant'],
  ['fad', 'narwhal'],
  ['fad', 'paper-plane'],
  ['fad', 'smile-beam'],
  ['fad', 'smile'],
  ['fad', 'grin-squint-tears'],
  ['fad', 'kiss-beam'],
  ['fad', 'grin-beam-sweat'],
  ['fad', 'grin-hearts'],
  ['fad', 'images'],
  ['fad', 'rocket-launch'],
  ['fad', 'hands-heart']
]
export default { Init }
